import Bookmark from '@components/icons/Bookmark'
import { DotsIcon } from '@components/icons/DotsIcon'
import ErrorBox from '@components/icons/ErrorBox'
import { Button, useUI } from '@components/ui'
import { useRouter } from 'next/router'
import { useState } from 'react'

const ExamRulesInstruction = ({ slug }) => {
  const { openModal, setModalView, modalView, closeModal, displayModal } =
    useUI()
  const [isChecked, setIsChecked] = useState(false)
  const router = useRouter()

  return (
    <div className="p-5">
      <div>
        <div className="text-center font-bold text-2xl">
          Instructions & Rules of Exam
        </div>
      </div>
      <ol className="mt-4 list-decimal ml-5">
        <li>
          When you click on <span className="font-bold">Continue </span> by
          agreeing with the rules, your
          <span className="font-bold"> Exam </span>will be started and the clock
          gets started.
        </li>
        <li>
          If you are late for the exam, you will have a clock according to the
          duration of the exam.
        </li>
        <li>
          The exam progress will be lost and your attempt is not counted if you
          press
          <span className="font-bold"> Back </span>on your browser.
        </li>
        <li>
          If you close the app, Your attempt is not counted until it gets
          submitted and results might not be populated.
        </li>
        <li>
          If you refresh the page, Your attempt is not counted until it gets
          submitted and results might not be populated.
        </li>
        <li>
          The <span className="font-bold">Submit </span>button is only present
          in the last question of the exams.
        </li>
        <li>
          After<span className="font-bold"> Submitting </span>the Exam, only
          then your attempt is counted.
        </li>
        <li>
          The exam will be<span className="font-bold"> Auto Submitted </span>
          after the clock runs out and your attempt is counted.
        </li>
        <li>
          You can navigate to questions using the
          <span className="font-bold"> Next </span>button or using{' '}
          <span className="font-bold">Question Palette. </span>
        </li>
        <li>
          <span className="font-bold">Results </span> will be populated and can
          have access by clicking on the
          <span className="font-bold"> SEE Results </span> button.
        </li>

        <li>Question Palettes hints:</li>
      </ol>
      <div className="flex items-center  ml-[10%]">
        <div>
          <div className="flex items-center">
            <div>
              <DotsIcon color="#4EFBDF" />
            </div>
            <div className="text-center ml-2">Current Question</div>
          </div>
          <div className="flex items-center">
            <div>
              {' '}
              <DotsIcon color="#FF9900" />
            </div>
            <div className="text-center  ml-2">Current Marked</div>
          </div>
          <div className="flex items-center ">
            <div>
              {' '}
              <DotsIcon color="#FF9900" />
            </div>
            <div className="text-center  ml-2"> Marked</div>
          </div>

          <div className="flex items-center">
            <div>
              {' '}
              <DotsIcon color="green" />
            </div>
            <div className="text-center  ml-2"> Answered</div>
          </div>

          <div className="flex items-center">
            <div>
              <DotsIcon color="red" />
            </div>
            <div className="text-center  ml-2">Un Answered</div>
          </div>

          <div className="flex">
            <div className="ml-[-5px]">
              <Bookmark
                className={'h-3'}
                filled={false}
                height={'30'}
                width={30}
              />
            </div>
            <div className=" ml-[-5px]">To Mark/Unmark The Question</div>
          </div>

          <div className="flex items-center mt-[-4px]">
            <div>
              <ErrorBox className={`h-3`}></ErrorBox>
            </div>
            <div className="text-center  ml-2">To Report Question</div>
          </div>
        </div>
      </div>

      <div className="flex justify-start my-5">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked)
          }}
          className="checkbox mr-5"
        />
        <span className="">
          I read all instructions above and agree with rules & regulations.
        </span>
      </div>

      <div className="flex justify-end">
        <Button className="mr-2" variant="outline" onClick={closeModal}>
          Cancel
        </Button>

        <Button
          variant="slim"
          disabled={!isChecked}
          onClick={() => router.push(`/exams/${slug}/attempt`)}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}

export default ExamRulesInstruction
