import React, { FC } from 'react'
interface Props {
  className?: string
}
const Lock: FC<React.PropsWithChildren<Props>> = ({ className }) => {
  return (
    <svg
      viewBox="0 0 32 32"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Private</title>
      <path
        d="M25.3333 14.666H6.66667C5.19391 14.666 4 15.8599 4 17.3327V26.666C4 28.1388 5.19391 29.3327 6.66667 29.3327H25.3333C26.8061 29.3327 28 28.1388 28 26.666V17.3327C28 15.8599 26.8061 14.666 25.3333 14.666Z"
        stroke="currentColor"
        strokeOpacity="1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 14.666V9.33268C9.3335 7.56457 10.0359 5.86888 11.2861 4.61864C12.5364 3.36839 14.2321 2.66602 16.0002 2.66602C17.7683 2.66602 19.464 3.36839 20.7142 4.61864C21.9644 5.86888 22.6668 7.56457 22.6668 9.33268V14.666"
        stroke="currentColor"
        strokeOpacity="1"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Lock
