import { InstructorProfile } from '@components/course'
import { Earth } from '@components/icons'
import LockIcon from '@components/icons/LockIcon'
import { Button, Modal, useUI } from '@components/ui'
import { MCQQuestionSetTypeStatus, SCOPE } from '@config/constants'
import { deleteQuestionSet } from '@lib/services/MCQ'
import getCourseOgImageUrl from '@lib/utils/getCourseOgImageUrl'
import toaster from '@lib/utils/notify'
import cn from 'classnames'
import moment from 'moment'
import { useAuthUser } from 'next-firebase-auth'
import { useTheme } from 'next-themes'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import e from './McqCard.module.css'
import ExamRulesInstruction from './questionSets/ExamRulesInstructions'

const McqCard = ({ data, refetch, positioning = 'horizontal' }) => {
  const { t } = useTranslation()
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState<boolean>()
  const currentUser = useAuthUser()
  const EXAM_RULES_INSTRUCTION = `EXAM_RULES_INSTRUCTION_${data?.id}`
  const { openModal, setModalView, modalView, closeModal, displayModal } =
    useUI()
  const router = useRouter()
  const { resolvedTheme: theme, setTheme } = useTheme()
  const locale = router.locale

  let endTime = moment(moment.utc(data?.endTime).toLocaleString()).format(
    'YYYY-MM-DDTHH:mm:ss'
  )
  let startTime = moment(moment.utc(data?.startTime).toLocaleString()).format(
    'YYYY-MM-DDTHH:mm:ss'
  )

  let isOnGoing = moment().isBetween(startTime, endTime)
  let hasEnded = moment().isAfter(endTime)
  let hasYetToStart = moment().isBefore(startTime)

  const confirmDeleteMCQQuestionSet = async () => {
    closeModal()
    if (data?.id) {
      try {
        setLoading(true)
        setMessage('')
        await deleteQuestionSet(locale, currentUser, data?.id)

        setLoading(false)
        await refetch()

        toaster.success('MCQ Question Set deleted successfully')
        setConfirm(false)
      } catch (r) {
        setLoading(false)
        setMessage(r.message)
      }
    }
  }

  return (
    <>
      {displayModal && modalView === EXAM_RULES_INSTRUCTION && (
        <Modal open={displayModal} onClose={closeModal}>
          <ExamRulesInstruction slug={data.slug} />
        </Modal>
      )}

      <div
        className={`${
          e[positioning] + ' ' + cn(e.root)
        } bg-base-200 card card-bordered relative ${
          positioning == 'vertical' && 'w-[300px]'
        } h-[90%]`}
      >
        <Link
          href={`/exams/${data.slug}`}
          className="absolute z-10 w-full h-full"
        ></Link>
        <Image
          height={positioning === 'vertical' ? '150' : '250'}
          width={'300'}
          src={`${
            data?.thumbnailUrl ||
            getCourseOgImageUrl(data?.user, data?.name, data?.thumbnail, theme)
          }`}
          alt={data?.name}
          className="bg-contain"
        />

        <div className={`card-body ${e.cardBodyTitle}`}>
          <div className="flex space-x-4 ">
            <div>
              {data?.teamSubTeams?.length > 0 && (
                <div className="badge bg-[#FE6196]  text-black mr-2">Teams</div>
              )}
              <div
                className={` min-w-[4rem] w-auto mr-2 badge  badge-outline  ${
                  data?.price > 0 ? ' badge-primary' : ' badge-accent'
                }`}
              >
                <span className="break-all">
                  {data?.price > 0 ? `NRP. ${data?.price}` : `Free`}
                </span>
              </div>
              <span className="p-0">
                {data?.scope == SCOPE.PUBLIC ? (
                  <span className=" tooltip" data-tip={t('public')}>
                    <Earth className=" w-5 "></Earth>
                  </span>
                ) : (
                  <span
                    className=" tooltip"
                    data-tip={t('Only my team member')}
                  >
                    <LockIcon className="h-5 w-5"></LockIcon>
                  </span>
                )}
              </span>
              {data?.userStatus === MCQQuestionSetTypeStatus.AUTHOR && (
                <div className=" badge bg-[#FE6196]  text-black mx-3">Host</div>
              )}
              {data?.userStatus === MCQQuestionSetTypeStatus.ENROLLED && (
                <div className=" badge bg-[#03FCB5] text-white mx-3">
                  Enrolled
                </div>
              )}
              {data?.userStatus === MCQQuestionSetTypeStatus.INVIGILATOR && (
                <div className=" badge bg-[#000dff] text-white mx-3">
                  Invigilator
                </div>
              )}
            </div>
          </div>

          <h2 className="card-title line-clamp-1 break-words">{data?.name}</h2>

          <div className="mt-4 flex flex-wrap justify-between z-20">
            <InstructorProfile instructor={data?.user} />
            <div
              className={`card-actions justify-end ${e.cardActions} items-center`}
            >
              {data?.userStatus === MCQQuestionSetTypeStatus.ENROLLED && (
                <div className="flex gap-3 items-center z-20">
                  {data?.remainingAttempt > 0 &&
                    !hasEnded &&
                    isOnGoing &&
                    !hasYetToStart && (
                      // <Link href={`/exams/${data.slug}/attempt`}>
                      // <a>
                      <>
                        <Button
                          className="mt-4 "
                          variant="slim"
                          onClick={() => {
                            setModalView(EXAM_RULES_INSTRUCTION)
                            openModal()
                          }}
                        >
                          {t('Attempt')}
                        </Button>
                        <Link href={`/exams/${data.slug}/student-result`}>
                          <Button variant="outline" className="mt-4">
                            {t('Result')}
                          </Button>
                        </Link>
                      </>
                      // </a>
                      // </Link>
                    )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default McqCard
